.ai-form-input {
    min-width: 300px;
    min-height: 65px;
    letter-spacing: 0.6px;
    color: $blue;
    text-indent: 20px;
    border: 2px solid $blue;
    border-radius: 15px;
    &::placeholder {
        color: $blue;
    }
    &:focus {
        box-shadow: 'none'
    }
}

.form-control {
    width: 300px;
    min-height: 65px;
    letter-spacing: 0.6px;
    color: $blue;
    text-indent: 20px;
    border: 2px solid $blue  !important;
    border-radius: 15px !important;
    background-color: none;
    &::placeholder {
        color: $blue;
    }
    &:focus {
        box-shadow: none !important
    }
}

form {
    .btn {
        background-color: transparent;
        border: none !important;
        &:hover {
            background-color: transparent;
        }
    }
}