.ai-button-row{
  display: inline-flex;
  flex-direction: row;
  width: fit-content;
  .ai-button{
    margin: 0 1rem;
  }
}

.ai-button{
  color: $black;
  text-decoration: none;
  display: inline-block;
  width: auto;
  border-radius: 100px;
  border: 2px solid $green;
  font-family: $ai-font-poppins ;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 25px;
  position: relative;
  overflow: hidden;
  z-index: 2;
  //Saving Button
  span.ai-btn-icon{
    margin-left: .5rem;
    display: inline-block;
    z-index: 2;
    svg{
      display: inline-block;
      width: 16px;
    }
  }
  //Button Background
  &:after{
    content: "";
    height: 200%;
    width: 200%;
    background-color: $green;
    position: absolute;
    left: -50%;
    top: -50%;
    border-radius: 100%;
    z-index: -1;
    transition: transform 1.5s ease;
  }
  //Button Hover Effect
  &:hover{
    color: $black;
    &:after{
      transform: translateY(100%);
    }
  //  Saving Button Hover
    span.ai-btn-icon{
      animation-name: aiSavingButtonAnimation;
      animation-duration: 1s;
      svg{

      }
    }
  }
}
//Saving Button Animation
@keyframes aiSavingButtonAnimation {
  0%{
    transform: rotate(0);
  }
  80%{
    transform: rotate(390deg);
  }
  100%{
    transform: rotate(360deg);
  }
}