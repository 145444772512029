.ai-card {
    padding: 2rem;
    color: $blue;
    border-radius: 15px !important;
    cursor: pointer;
    .ai-card-bottom {
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
        padding-bottom: 30px;
        padding-top: 45px;
        position: relative;
        display: block;
        min-width: 150px;
        text-decoration: none;
        padding-left: 30px;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
              
    }
    
        
    &.ai-card-animated {
        display: block;
        position: relative;
        min-width: 100px;
        background-color: $cyan;
        border-radius: 4px;
        text-decoration: none;
        z-index: 0;
        overflow: hidden;

        img {
            position: relative;
            -webkit-transition: all .5s ease-out;
            -moz-transition: all .5s ease-out;
            -o-transition: all .5s ease-out;
            -ms-transition: all .5s ease-out;
            transition: all .5s ease-out;
        }

        &:before {
            content: "";
            position: absolute;
            z-index: -3;
            bottom: -16px;
            left: -16px;
            background:  $cyan;
            height: 8px;
            width: 8px;
            border-radius: 32px;
            transform: scale(1);
            transform-origin: 50% 50%;
            transition: transform 0.25s ease-out;
        }

        &:hover:before {
            transform: scale(100);
            z-index: -2;
        }
        &:hover {
            p {
                // transition: all 0.3s ease-out;
                // color: $white;
              }
            
        }
          
    }
}
.ai-card-animated:hover {
    img {
        
        transform: translate(15px, 0);
        -webkit-transition: all .5s ease-out;
        -moz-transition: all .5s ease-out;
        -o-transition: all .5s ease-out;
        -ms-transition: all .5s ease-out;
        transition: all .5s ease-out;

    }
}

.ai-card-animated-bg:hover .ai-card-img-bg {
    .ai-card-img-bg{
        // transform: scale(.5) rotate(1deg);
    }
}

body {background: #fff;}


.ai-card-img-bg{
	height: 100%;
	width: 100%;
	overflow: hidden !important;
	background-size: 100%;
	transition: all ease-in-out .4s;
    border-bottom-right-radius: 'none';
    border-bottom-left-radius: 'none';
    z-index: 0;

}

.card:hover .ai-card-img-bg{
    z-index: -1;
	transform: scale(1.02);
}
.card:hover .ai-card-bottom { 
    background-color: transparent !important;
    border-color: transparent !important;
}


.ai-card-article  {
    display: block;
    position: relative;
    overflow: hidden;
    padding: 0;
    z-index: 1;

    i {
        color: $blue;
        font-size: 23px;
    }

    h6 {
        font-size:14px;
    }

    &:before {
        content: "";
        position: absolute;
        z-index: -3;
        bottom: -16px;
        left: -16px;
        background:  $cyan;
        height: 8px;
        width: 8px;
        border-radius: 32px;
        transform: scale(1);
        transform-origin: 20% 50%;
        transition: transform 0.25s ease-out;
    }

    &:hover:before {
        transform: scale(100);
    }
}

@keyframes slide {
    0%,
    100% {
      transform: translate(0, 0);
    }
  
    50% {
      transform: translate(10px, 0);
    }
  }
  