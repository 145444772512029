/* VENDOR*/

@import 'vendor/ai-bootstrap';

@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";

/* UTILS*/
@import 'utils/ai-variables';
@import 'utils/ai-commons';
@import 'utils/ai-fonts';
@import "utils/ai-responsive";


/* COMPONENTS*/

//header
@import 'components/header/ai-navigation';
@import 'components/header/ai-dropdown';
@import 'components/header/ai-search-bar';
@import 'components/header/ai-hover-animation';

//pages
@import 'components/pages/ai-landing-hero';
@import 'components/pages/ai-switched';
@import 'components/pages/ai-reviews';
@import 'components/pages/ai-slider-bar';

@import 'components/abstract/ai-cta';
@import 'components/abstract/ai-singe-page-nav';



//footer
@import 'components/footer/ai-footer';
@import 'components/footer/ai-footer-banner';

//card
@import 'components/ai-card';


//form
@import 'components/abstract/ai-input';
@import 'components/abstract/ai-checkbox';
@import 'components/abstract/ai-textarea';
@import 'components/abstract/ai-range-slider';

@import 'components/ai-card';
@import 'components/ai-button';
@import 'components/ai-page-header';
@import 'components/ai-tabs';

/* PAGES*/
@import 'pages/ai-home';

