.dropdown-menu {
    
    min-width: 270px !important;
    position: absolute!important;
    background-color: $cyan !important;
    color: $blue !important;
    border-radius: 0 10px 10px 10px !important;
    padding: 10px !important;
    list-style: none !important;
    border: none !important;
    transition: ease-in-out .2s;
    margin: 0 !important;
    .dropdown-item {
        color: $primary-blue;
        font-size: 14px !important;
        height: 30px !important;
        -webkit-transition: all .5s ease-out;
        -moz-transition: all .5s ease-out;
        -o-transition: all .5s ease-out;
        -ms-transition: all .5s ease-out;
        transition: all .5s ease-out;
        &:hover {
            background: unset !important;
            color: $white;
        }

        &:focus {
            background: unset !important;
        }
    }
}