.ai-snip * {
    box-sizing: border-box !important;
    -webkit-transition: all 0.35s ease !important;
    transition: all 0.35s ease !important;
  }
  .ai-snip li {
    display: inline-block !important;
    list-style: outside none none !important;
    // margin: 0 1.5em !important;
    overflow: hidden !important;
  }
  .ai-snip .ai-snip-link {
    // padding: 0.3em 0 !important;
    position: relative !important;
    display: inline-block !important;
    margin: 0 !important;
    text-decoration: none !important;
  }
  .ai-snip .ai-snip-link:before,
  .ai-snip .ai-snip-link:after {
    position: absolute !important;
    -webkit-transition: all 0.35s ease !important;
    transition: all 0.35s ease !important;
  }
  .ai-snip .ai-snip-link:before {
    bottom: 100% !important;
    display: block !important;
    // height: 3px !important;
    width: 100% !important;
    content: "" !important;
  }
  .ai-snip .ai-snip-link:after {
    // padding: 0.3em 0 !important;
    position: absolute !important;
    bottom: 100% !important;
    left: 0 !important;
    content: attr(data-hover);
    white-space: nowrap;
    margin-left: 15px;
  }
  .ai-snip li:hover .ai-snip-link,
  .ai-snip .current .ai-snip-link {
    transform: translateY(100%) !important;
  }