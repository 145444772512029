

nav {
    height: 150px;
    z-index: 3;
    background-color: transparent;
   
    &.ai-fixed-top {
        // animation: fadeIn .5s;
        height: 75px;
        transition: height 500ms ease-in-out;
        .ai-app-logo {
            width: 100px;
            transition: width 500ms ease-in-out;
        }
    }

    .navbar-toggler {
        border-color: none;
        border: 0 !important;
        .navbar-toggler-icon {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
        }
        &:focus {
            border: 0 !important;
            outline: unset !important;
            box-shadow: none !important;
        }
    }
  
    .ai-app-logo {
        width: 150px;
        z-index: 1;
    }
       
        .navbar-collapse {
            // display: flex;
            // justify-content: end;
          
            .nav-item {
                position: relative;
                margin-right: 15px;
                z-index: 1;
                padding-top: 20px;
                padding-bottom: 20px;
                .dropdown-toggle::after {
                    box-sizing: border-box;
                    width: 8px;
                    height: 8px;
                    border-style: solid;
                    border-color: $white;
                    border-width: 0px 2px 2px 0px;
                    transform: rotate(45deg);
                    position: absolute;
                    top: 12px;
                    right: 0px;
                    &:hover {
                        border-color: $green !important;
                        transition: border-width 250ms ease-in-out;
                        background-color: $green !important;
                    }
                }
                .nav-link {
                    color:$white;
                    font-size: 14px;
                    position: relative;
                    z-index: 1;
                    
                    .ai-search-icon {
                        cursor: pointer;
                    }

                    &:hover {
                        color: $green;
                        transition:  250ms ease-in-out;
                    }
                    
                }
            }
        }
    
}

.dropdown-toggle:hover:after {
    border-color: $green !important;
    transition:  250ms ease-in-out;
}
@media only screen and (min-width: 700px) {
    .navbar-collapse {
        display: flex;
        justify-content: end;
    }
}


@media only screen and (max-width: 599px) {

    nav {
        background-color: $blue;
        .nav-item {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }
        .ai-nav-container {
            padding-top: 40px;
            padding-bottom: 40px;
            background-color: $blue !important;
            // height: 150px;
        }
        .ai-expanded{
            background-color: $blue !important;
            .navbar-toggler {
                .navbar-toggler-icon {
                    -o-transform:rotate(720deg);
                    -ms-transform:rotate(720deg);
                    -moz-transform:rotate(720deg);
                    -webkit-transform:rotate(720deg);
                    transform:rotate(720deg);
                transition: 0.2s;
    
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E")!important;
                }
            }
        }
        .navbar-collapse {
            flex-direction: column;
            align-items: flex-start;
            background-color: $blue;
            padding:  2rem 0;
            .dropdown-toggle::after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E") !important;
            // padding-left: ;
            }
            &.show {
                display: block;
                height: 100vh;
                overflow-y: hidden;
                // transition: ease-in-out 0.5s;

                .dropdown-item {
                    color: $white !important;
                    padding-bottom: 1rem;
               }
            }
            
        }
        .dropdown-menu {
            position: static !important;
            background-color: transparent !important;
            border: none !important;
           .dropdown-item {
                color: $white !important;
           }
        }
        
    }

    .modal-body {
        display: flex !important;
        justify-content: center !important;
        width: 80% !important;

        .ai-modal-close {
            position: absolute;
            margin: 0;
            top: 10px;
            right: 17px;
        }
    }
    .modal-header {
        display: flex !important;
        justify-content: center !important;
        
    }

    .ai-hide-desktop {
        display: none !important;
    }
    .ai-search-bar {
        top: 70px !important;
       
        .ai-button {
            width: 80px !important;
            padding: 5px !important;
        }
    }
    .ai-search-wrapper {
        display: block !important;
    }
}

//mobile menu




@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
       opacity: 1;
    }
}