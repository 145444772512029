.ai-text-area {
    min-width: 400px;
    min-height: 100px;
    letter-spacing: 0.6px;
    color: #0C233D;
    text-indent: 20px;
    border: 2px solid #0C233D;
    border-radius: 15px;
    text-indent: 1.5em;
    padding-top:1.5rem;
    &::placeholder {
        color: $blue;
    }
    &:focus {
        box-shadow: 'none'
    }
    
}