.ai-page-nav {
    min-width: 600px;
    // height: 250px;
    padding: 2.5rem;
    cursor: pointer;
    transition: all ease-out .5s;
    h3 {
        margin: 0;
        font-size: 30px;
        line-height: 15px;
        padding-top: 10px;
    }
    p {
        margin: 0;
        padding-bottom: 10px;
    }
    &:hover {
        box-shadow: inset 250px 250px 250px 250px rgba(38, 220, 251, .6);
        box-sizing: border-box;
        transition: all ease-in .5s;
    }
    &:hover h3  { 
        color: $blue !important; 
    }
    
    &:hover p  { color: $blue !important; }

    &:hover  .ai-arrow {
        border-color: $blue;
    }

    &:hover  .ai-arrow-next{
        animation: slideNext 1s ease-in-out infinite;

    }

    &:hover  .ai-arrow-prev{
        animation: slidePrev 1s ease-in-out infinite;
    }

    &:hover  .ai-arrow::after{
        background-color: $blue;
    }
    .ai-arrow {
        display: block;
        // margin: 30px auto;
        width: 15px;
        height: 15px;
        border-top: 2px solid $white;
        border-left: 2px solid $white;
        transition: position .5s ease;

        &.ai-arrow-prev {
            transform: rotate(-45deg);
        }
        &.ai-arrow-next{
            transform: rotate(135deg);
        }
        &::after {
          content: "";
          display: block;
          width: 2px;
          height: 45px;
          background-color: $white;
          transform: rotate(-45deg) translate(15px, 4px);
          left: 10px;
          top: 0;
          transition: position 1.5s ease-out;
          } 
    }
   
}

@keyframes slideNext {
    0%,
    100% {
        position: relative;
        left: 0;
      
    }
  
    50% {
        position: relative;
        left: 20px;
    }
  }
    

  @keyframes slidePrev {
    0%,
    100% {
        position: relative;
        left: 0;
      
    }
  
    50% {
        position: relative;
        left: -20px;
    }
  }
    