.ai-footer {
    background-color: $blue;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .ai-footer-wrapper {
        
        h4 {
            font-size: 21px;
        }
        .ai-footer-divider {
            height: 3px;
            background-color: $cyan;
            margin-top: 60px;
            margin-bottom: 60px;
        }
        .ai-copy-right {
            font-size: 12px;
        }

        .nav-link {
            font-size: 14px;
            color: $white;
        }

        .ai-footer-icon {
            width: 50px;
            height:50px;
            border-radius: 15px;
            margin-right: 10px;
            cursor: pointer;
            i {
                color: $green;
                margin: 0 auto;
                font-size: 24px;
            }
            &.ai-social-icons {

                 display: block;
                position: relative;
                min-width: 50px;
                text-decoration: none;
                z-index: 0;
                overflow: hidden;

                &:before {
                    content: "";
                    position: absolute;
                    z-index: -3;
                    bottom: 0;
                    left: 0;
                    background:  $cyan;
                    height: 2px;
                    width: 2px;
                    border-radius: 32px;
                    transform: scale(1);
                    transform-origin: 50% 50%;
                    transition: transform 0.75s ease-out;
                }

                &:hover:before {
                    transform: scale(100);
                    
                }
               
            }

            &.ai-contact-icons {

                display: block;
               position: relative;
               min-width: 50px;
               text-decoration: none;
               z-index: 0;
               overflow: hidden;

               &:before {
                   content: "";
                   position: absolute;
                   z-index: -3;
                   bottom: 0;
                   left: 0;
                   background:  $green;
                   height: 2px;
                   width: 2px;
                   border-radius: 32px;
                   transform: scale(1);
                   transform-origin: 50% 50%;
                   transition: transform 0.75s ease-out;
               }

               &:hover:before {
                   transform: scale(100);
                    i {
                        color: $white;
                    }
               }

               &:hover i {
                    color: $white;
                }

               
              
           }

            
            
        }
        .ai-footer-text {
            padding-left: 10px;
        }
    }

    .ai-wtsp {
        position: absolute;
        right: 32px;
        bottom: 10px;
    }
    
    .word-wrapper span {
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
    }
   
}

.ai-footer-wtsp {
    width: 55px;
    height: 55px;
    background-color: $green;
    border-radius: 50px;
    img {
        width: 48px;
    }
}

.ai-mobile-footer {
    background-color: $blue;
    .ai-footer-icon {
        width: 50px;
        height:50px;
        border-radius: 15px;
        margin-right: 10px;
        cursor: pointer;
        i {
            color: $green;
            margin: 0 auto;
            font-size: 24px;
        }
    }

} 

.ai-footer-contact {
    h4 {
        transition: all 0.75s ease-out;
        &:hover {
            color: $white !important;
            
        }
    }
}