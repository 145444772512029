.ai-search-bar  {
    width: 100%;
    .ai-search-bar-form {
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 30px;
        background: $white;
        border-radius: 5px 5px 5px 5px;
        input {
            height: 50px;
            outline: none;
            color: $primary-blue;
            width: 100%;
            font-size: 16px;
            font-family: 'Poppins', sans-serif;
            text-indent: 30px;
            border-bottom-right-radius: 0 !important;
            border-bottom-left-radius: 0;
            border: none;
            &::placeholder {
                font-size: 14px;
                color: $primary-blue;
            }
            &:focus {
                border: 0;
                outline: none;
            }
        }
    
    }

    .ai-suggestions{
        box-shadow: unset;
        list-style: none;
        background: $white;
        padding: 0;
        border-radius: 0 0 5px 5px;
        li {
            color: $primary-blue;
            font-size: 16px;
            height: 50px;
            vertical-align: middle;
            text-indent: 15px;
            display: flex;
            flex-direction: row;
            align-items: center;
            text-indent: 30px;
            &:hover {
                background-color: $grey;
            }
            &:last-child {
                border-radius: 0 0 5px 5px;
            }
        }
        
    }
}

.ai-search-wrapper {
    display: flex;
}

.ai-search-bar-modal {
    width: 100%;
    border: none !important;
    .modal-content {
        background-color:transparent !important;
    }

    .modal-body {
        margin: 0 auto !important;
        overflow: hidden;
    
    }
    .ai-modal-close {
        color: #00E881;
        font-size: 16px;
        text-decoration: none;
        margin-left: 20px;
        i {
            color: $green;
            font-size: 16px;
            padding-right: 3px;
        }

    }

    .modal-header {
        border: none;
        background: $primary-blue;
        top: 0;
        position: absolute;
        left: 0;
        right: 0;
        height: 150px;
        display: flex;
        justify-content: end;
        align-items: center;
    }
}
