.ai-landing-hero {
    height: 100vh;
  
    .ai-container {
        margin-top: 150px;
        height: 500px;
        h1 {
            font-size: 90px;
        }
        .ai-landing-hero-img {
            // width: 770px;
        }
    }
}